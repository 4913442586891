import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';

@Component({
  selector: 'app-grievance',
  templateUrl: './grievance.component.html',
  styleUrls: ['./grievance.component.scss']
})
export class GrievanceComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;


  concern:any='';

  btntxt:any = 'Add Grievance';

  uconcern:any='';

  ubtntxt:any = 'Add Grievance Comments';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  list:any = '';
  institute:any = '';
  userid:any = '';
  gstatus:any = '';
  comments:any = '';
  ucomments:any = '';
  constructor(private apiService: ApiService,private session: SessionService,) {
    this.myrole = sessionStorage.getItem("role");
    this.institute = sessionStorage.getItem("institute");
    this.userid = sessionStorage.getItem("userid");
    this.get();

  }

  ngOnInit(): void {
  }

  view(id) {
    this.onTab(3);
    this.currentid=id;
    this.single(id);
    this.getcomments();
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchvalues(searchval) {
    this.itemData = {
        search:searchval,
        "identity":'grievance'
      }
      this.apiService.post('search-data', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  single(id) {
      this.itemData = {
        "id":id,
        "identity":'grievance'
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {
          this.uconcern=res.data[0].grievance;
          this.gstatus=res.data[0].gstatus;
      });
  }

  remove(id) {
    if(confirm("Are you sure you want to delete this Grievance?")) {
        this.itemData = {
          "id":id,
          "identity":'course'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.get();
        });
    }
  }

  get() {
      this.itemData = {
        "identity":'grievance'
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  getcomments() {
      this.itemData = {
        "identity":'grievance_comments',
        "grievanceid":this.currentid
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.comments=res.data;
      });
  }

  async add() {
      this.ename=0;
      this.vcount=0;
      if(this.concern=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "userid":this.userid,
            "concern":this.concern,
            "identity":'grievance'
          }
          await this.apiService.post('insert', this.itemData).then((res: any) => {
            this.btntxt="Adding Grievance..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Grievance';
                this.btndisabled=0;
                this.concern='';
                this.session.myLoader(0);
                this.get();
                this.onTab(1);
              }
          });
      }
  }

  async update() {
    this.uename=0;
    this.vcount=0;
    if(this.ucomments=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "userid":this.userid,
          "ucomments":this.ucomments,
          "grievanceid":this.currentid,
          "gstatus":this.gstatus,
          "identity":'grievance_comments'
        }
        await this.apiService.post('insert', this.itemData).then((res: any) => {
          this.ubtntxt="Adding Grievance Comments..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Add Grievance Comments';
              this.btndisabled=0;
              this.ucomments='';
              this.gstatus='';
              this.session.myLoader(0);
              this.getcomments();
              this.get();
            }
        });
    }
  }

}
