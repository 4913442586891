import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  title:any  = 'Transactions';

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  entrytab:any = 1;

  btntxt:any = 'Add Entry';
  ubtntxt:any = 'Edit Entry';

  voucherno:any = '';
  tdate:any ='';
  etdate:any = 0;

  itemData:any;
  btndisabled:any = 0;
  message:any;

  vcount:any = 0;

  cid:any;
  currentid:any;
  search:any = '';
  myrole:any;
  fromledgerlist:any='';
  toledgerlist:any = '';

  transactions:any = '';
  debitledger:any;
  creditledger:any;

  entryForm: FormGroup;

  total:any = 0;
  remarks:any = '';
  fy:any = '';
  ledgercredit:any = 0;

  pageno:any = 1;
  total_pages:any = 1;
  numbers:any ;
  exportlist:any = '';
  newpageno:any = 1;

  constructor(private apiService: ApiService, private formBuilder: FormBuilder) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getTransactions();
    this.getLedgers();
  }

  ngOnInit(): void {
      this.entryForm = this.formBuilder.group({
        items: this.formBuilder.array([ this.createItem() ])
      });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
        ledgerdebit: ['', [Validators.required]],
        narration: ['', [Validators.required]],
        amount: ['', [Validators.required]],
    });
  }

  addItem(): void {
    //this.items = this.purchaseForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  deleteRow(index: number) {
    this.items.removeAt(index);
    //this.calculate(0, index);
  }

  get items() {
    return this.entryForm.get('items') as FormArray
  }

  getTransactions() {
      this.itemData = {
        "cid":this.cid,
        "pageno":this.pageno,
        "search":this.search
      }
      this.apiService.post('transactions', this.itemData).then((res: any) => {
          this.transactions=res.data;
          this.total_pages=Math.ceil(res.total);
          this.numbers = [];
          for (let index = 1; index <= this.total_pages; index++) {
            this.numbers.push(index);
          }
      });
  }

  searchTransactions() {
      this.itemData = {
        "cid":this.cid,
        "pageno":this.pageno,
        "search":this.search
      }
      this.apiService.post('transactions', this.itemData).then((res: any) => {
          this.transactions=res.data;
          this.total_pages=Math.ceil(res.total);
          this.numbers = [];
          for (let index = 1; index <= this.total_pages; index++) {
            this.numbers.push(index);
          }
      });
  }

  searchTransactionsNew() {
    this.pageno=1;
    this.itemData = {
        "cid":this.cid,
        "pageno":this.pageno,
        "search":this.search
      }
      this.apiService.post('transactions', this.itemData).then((res: any) => {
          this.transactions=res.data;
          this.total_pages=Math.ceil(res.total);
          this.numbers = [];
          for (let index = 1; index <= this.total_pages; index++) {
            this.numbers.push(index);
          }
      });
  }

  getLedgers() {
      this.itemData = {
        "cid":this.cid,
        "type":this.entrytab
      }
      this.apiService.post('entry-ledgers', this.itemData).then((res: any) => {
          this.debitledger=res.debit;
          this.creditledger=res.credit;
      });
  }

  async newEntry() {
      this.itemData = {
        "cid":this.cid,
        "voucherno":this.voucherno,
        "tdate":this.tdate,
        "ledgercredit":this.ledgercredit,
        "items": this.entryForm.value,
        "total":this.total,
        "remarks":this.remarks,
        "entrytype":this.entrytab
      }
      await this.apiService.post('entry-payments', this.itemData).then((res: any) => {
        this.voucherno='';
        this.tdate='';
        this.ledgercredit='';
        //this.entryForm.value='';
        this.entryForm = this.formBuilder.group({
          items: this.formBuilder.array([ this.createItem() ])
        });
        this.total=0;
        this.remarks='';
        this.getTransactions();
        this.onTab(1);
      });
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    //this.getSingle(id);
  }

  changeEntry(id:any) {
    this.entrytab=id;
    this.getLedgers();
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }





  calculate(val, index) {

    this.total=0;
    if(this.items.length>0) {
      if(isNaN(this.items.at(index).get('amount').value)) {
        this.items.at(index).get('amount').setValue(0);
      }
      for (const control of this.items.controls) {
        this.total=+this.total + +control.value['amount'];
        this.remarks=control.value['narration']
      }

    } else {
      this.total = 0;
    }
  }
  prev() {
    this.pageno=this.pageno - 1;
    this.newpageno = this.pageno;
    this.searchTransactions();
  }

  next() {
    this.pageno=this.pageno + 1;
    this.newpageno = this.pageno;
    this.searchTransactions();
  }

  last() {
    this.pageno=this.total_pages;
    this.newpageno = this.pageno;
    this.searchTransactions();
  }

  first() {
    this.pageno=1;
    this.newpageno = this.pageno;
    this.searchTransactions();
  }

  getPage() {
    if(isNaN(this.newpageno)) {
      this.newpageno=this.pageno;
    }
    if(this.newpageno>this.total_pages) {
      this.newpageno=this.total_pages;
    }
    if(this.newpageno<1) {
      this.newpageno=1;
    }
    if(this.pageno<=this.total_pages && this.pageno>0) {
      this.pageno=this.newpageno;
      this.searchTransactions();
    }
  }

  async exportdata() {
    this.itemData = {
        "cid":this.cid,
        "pageno":-1
      }
      await this.apiService.post('transactions', this.itemData).then((res: any) => {
          this.exportlist=res.data;
      });
  }

  export() {
    const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.title + '.xlsx');
  }

}
