import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-userallowance',
  templateUrl: './userallowance.component.html',
  styleUrls: ['./userallowance.component.scss']
})
export class UserallowanceComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  name:any='';
  description:any='';

  btntxt:any = 'Add Allowance';

  uname:any='';
  udescription:any='';

  ubtntxt:any = 'Edit Allowance';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  list:any = '';

  userid:any = '';
  username:any = '';

  allowanceid:any = '';
  allowances:any = '';
  amount:any = 0;
  allowancetype:any = '1'

  eallowanceid:any = 0;
  eamount:any = 0;
  constructor(private apiService: ApiService,private session: SessionService,private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.myrole = sessionStorage.getItem("role");
    this.userid = this.activatedRoute.snapshot.paramMap.get('id');
    this.username = this.activatedRoute.snapshot.paramMap.get('name');
    this.get();
    this.getAllowance();
  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.single(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchvalues(searchval) {
    this.itemData = {
        search:searchval,
        "identity":'user_allowance'
      }
      this.apiService.post('search-data', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  single(id) {
      this.itemData = {
        "id":id,
        "identity":'user_allowance'
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {
          this.uname=res.data[0].allowance;
          this.udescription=res.data[0].description;
      });
  }

  remove(id) {
    if(confirm("Are you sure you want to delete this Allowance?")) {
        this.itemData = {
          "id":id,
          "identity":'user_allowance'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.get();
        });
    }
  }

  get() {
      this.itemData = {
        "identity":'user_allowance'
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  getAllowance() {
    this.itemData = {
      "identity":'allowance'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.allowances=res.data;
    });
  }

  async add() {
      this.ename=0;
      this.vcount=0;
      if(this.allowanceid=='' || this.allowanceid==0) {
        this.eallowanceid=1;
        this.vcount=1;
      }
      if(this.amount==0) {
        this.eamount=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "userid":this.userid,
            "allowanceid":this.allowanceid,
            "amount":this.amount,
            "allowancetype":this.allowancetype,
            "identity":'user_allowance'
          }
          await this.apiService.post('insert', this.itemData).then((res: any) => {
            this.btntxt="Adding Allowance..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Allowance';
                this.btndisabled=0;
                this.list=res.data;
                this.name='';
                this.description='';
                this.session.myLoader(0);
                this.get();
                this.onTab(1);
              }
          });
      }
  }

  async update() {
    this.uename=0;
    this.vcount=0;
    if(this.uname=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "id":this.currentid,
          "name":this.uname,
          "description":this.udescription,
          "identity":'allowance'
        }
        await this.apiService.post('update-data', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Allowance..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Allowance';
              this.btndisabled=0;
              this.list=res.data;
              this.uname='';
              this.udescription='';
              this.session.myLoader(0);
              this.get();
              this.onTab(1);
            }
        });
    }
  }

}
