import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../../api.service';

import { SessionService } from '../../../../session.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  name:any='';
  description:any='';

  btntxt:any = 'Add Department';

  uname:any='';
  udescription:any='';

  ubtntxt:any = 'Edit Department';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  isoffice:boolean = false;
  ishr:boolean = false;
  isadmin:boolean = false;
  isproject:boolean = false;
  isfinance:boolean = false;


  eisoffice:boolean = false;
  eishr:boolean = false;
  eisadmin:boolean = false;
  eisproject:boolean = false;
  eisfinance:boolean = false;
  exportlist:any = '';
  title:any  = 'Department List';
  constructor(private apiService: ApiService,private session: SessionService,) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getcategories();

  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.getSingle(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchcategory(searchval) {
    this.itemData = {
        "id":this.cid,
        search:searchval
      }
      this.apiService.post('search-categories', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  getSingle(id) {
      this.itemData = {
        "id":this.cid,
        "cid":id
      }
      this.apiService.post('get-category', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.udescription=res.data[0].description;
          this.eisoffice=res.data[0].office;
          this.eishr=res.data[0].hr;
          this.eisadmin=res.data[0].admin;
          this.eisproject=res.data[0].project;
          this.eisfinance=res.data[0].finance;
      });
  }

  removeCategory(id) {
    if(confirm("Are you sure you want to delete this Department?")) {
        this.itemData = {
          "id":this.cid,
          "cid":id
        }
        this.apiService.post('remove-category', this.itemData).then((res: any) => {
              this.catlist=res.data;
        });
    }
  }

  getcategories() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('categories', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  async addcategory() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "description":this.description,
            "ishr":this.ishr,
            "isadmin":this.isadmin,
            "isoffice":this.isoffice,
            "isproject":this.isproject,
            "isfinance":this.isfinance
          }
          await this.apiService.post('category', this.itemData).then((res: any) => {
            this.btntxt="Adding Category..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Category';
                this.btndisabled=0;
                this.catlist=res.data;
                this.name='';
                this.description='';
                this.session.myLoader(0);
                this.onTab(1);
              }
          });
      }
  }

  async editcategory() {
    this.uename=0;
    this.vcount=0;
    if(this.uname=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "id":this.cid,
          "name":this.uname,
          "description":this.udescription,
          "cid":this.currentid,
          "ishr":this.eishr,
          "isadmin":this.eisadmin,
          "isoffice":this.eisoffice,
          "isproject":this.eisproject,
          "isfinance":this.eisfinance
        }
        await this.apiService.post('edit-category', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Department..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Department';
              this.btndisabled=0;
              this.catlist=res.data;
              this.uname='';
              this.udescription='';
              this.session.myLoader(0);
              this.onTab(1);
            }
        });
    }
  }


  async exportdata() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('categories', this.itemData).then((res: any) => {
          this.exportlist=res.data;
      });
  }

  export() {
    const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.title + '.xlsx');
  }

}
