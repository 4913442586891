import { Injectable } from '@angular/core';
import { Observable, of, Subscription, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

    myUserid$: Observable<any>;
    myName$: Observable<any>;
    myRole$: Observable<any>;
    myAccess$: Observable<any>;
    myCompany$: Observable<any>;
    myCid$: Observable<any>;
    myLogo$: Observable<any>;
    myPermission$: Observable<any>;
    myLoader$: Observable<any>;
    myFy$: Observable<any>;
    myHr$: Observable<any>;
    myOffice$: Observable<any>;
    myAdmin$: Observable<any>;
    myProject$: Observable<any>;
    myFinance$: Observable<any>;
    myInstitute$: Observable<any>;

    private myUseridstatus = new Subject<any>();
    private myNamestatus = new Subject<any>();
    private myRolestatus = new Subject<any>();
    private myAccessstatus = new Subject<any>();
    private myCompanystatus = new Subject<any>();
    private myCidstatus = new Subject<any>();
    private myLogostatus = new Subject<any>();
    private myPermissionstatus = new Subject<any>();
    private myLoaderstatus = new Subject<any>();
    private myFystatus = new Subject<any>();
    private myHrstatus = new Subject<any>();
    private myOfficestatus = new Subject<any>();
    private myAdminstatus = new Subject<any>();
    private myProjectstatus = new Subject<any>();
    private myFinancestatus = new Subject<any>();
    private myInstitutestatus = new Subject<any>();

    constructor() {
        this.myUserid$ = this.myUseridstatus.asObservable();
        this.myName$ = this.myNamestatus.asObservable();
        this.myRole$ = this.myRolestatus.asObservable();
        this.myAccess$ = this.myAccessstatus.asObservable();
        this.myCompany$ = this.myCompanystatus.asObservable();
        this.myCid$ = this.myCidstatus.asObservable();
        this.myLogo$ = this.myLogostatus.asObservable();
        this.myPermission$ = this.myPermissionstatus.asObservable();
        this.myLoader$ = this.myLoaderstatus.asObservable();
        this.myFy$ = this.myFystatus.asObservable();
        this.myHr$ = this.myHrstatus.asObservable();
        this.myOffice$ = this.myOfficestatus.asObservable();
        this.myAdmin$ = this.myAdminstatus.asObservable();
        this.myProject$ = this.myProjectstatus.asObservable();
        this.myFinance$ = this.myFinancestatus.asObservable();
        this.myInstitute$ = this.myInstitutestatus.asObservable();
    }

    myInstitute(data) {
        this.myInstitutestatus.next(data);
    }

    myHr(data) {
        this.myHrstatus.next(data);
    }

    myOffice(data) {
        this.myOfficestatus.next(data);
    }

    myAdmin(data) {
        this.myAdminstatus.next(data);
    }

    myProject(data) {
        this.myProjectstatus.next(data);
    }

    myFinance(data) {
        this.myFinancestatus.next(data);
    }

    myFy(data) {
        this.myFystatus.next(data);
    }

    myLoader(data) {
        this.myLoaderstatus.next(data);
    }

    myUserid(data) {
        this.myUseridstatus.next(data);
    }

    myName(data) {
        this.myNamestatus.next(data);
    }

    myRole(data) {
        this.myRolestatus.next(data);
    }

    myAccess(data) {
        this.myAccessstatus.next(data);
    }

    myCompany(data) {
        this.myCompanystatus.next(data);
    }

    myCid(data) {
        this.myCidstatus.next(data);
    }

    myLogo(data) {
        this.myLogostatus.next(data);
    }

    myPermission(data) {
        this.myPermissionstatus.next(data);
    }
}
