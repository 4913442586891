import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-projectedit',
  templateUrl: './projectedit.component.html',
  styleUrls: ['./projectedit.component.scss']
})
export class ProjecteditComponent implements OnInit {

  addTab: boolean = true;
  listTab: boolean;
  cid:any;
  id:any = '';

  name:any = '';
  userid:any = '';
  client:any = '';
  forclient:any = '';
  clientid:any = '';
  description:any = '';
  start_date:any = '';
  end_date:any = '';
  status:any = '';
  progress:any = 0;
  cost:any = 0;

  btntxt:any = 'Add Project';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  clientlist:any;
  statuslist:any;
  projectlist:any;
  pid:any;

  estartdate:any = 0;
  eenddate:any = 0;
  vcount:any = 0;
  ename:any = 0;
  eclient:any = 0;
  estatus:any = 0;

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute,) {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getClients();
    this.getStatus();
    this.getProjects();
  }

  ngOnInit(): void {
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.profileimage = file;
      }
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
  }

  getClients() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('clients', this.itemData).then((res: any) => {
          this.clientlist=res.data;
      });
  }

  getProjects() {
      this.itemData = {
        "id":this.cid,
        "project":this.id
      }
      this.apiService.post('project-view', this.itemData).then((res: any) => {
          this.projectlist=res.data;
          this.client=res.data[0].clientid;
          this.name=res.data[0].name;
          this.clientid=res.data[0].clientid;
          this.forclient=res.data[0].forclient;
          this.description=res.data[0].description;
          this.start_date=res.data[0].start_date;
          this.end_date=res.data[0].end_date;
          this.status=res.data[0].status;
          this.progress=res.data[0].progress;
          this.cost=res.data[0].cost;

      });
  }

  getStatus() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('allstatus', this.itemData).then((res: any) => {
          this.statuslist=res.data;
      });
  }

  edit(id) {
    this.router.navigate(['/project-edit/', { id: id }]);
  }

  async upload() {
    this.vcount=0;
    this.estartdate=0;
    this.eenddate=0;
    this.ename=0;
    this.eclient=0;
    this.estatus=0;
    if(this.name=='' || this.name==undefined) {
      this.vcount=1;
      this.ename=1;
    }
    if(this.client=='' || this.client==undefined) {
      this.vcount=1;
      this.eclient=1;
    }
    if(this.client=='' || this.client==undefined) {
      this.vcount=1;
      this.eclient=1;
    }
    if(this.status=='' || this.status==undefined) {
      this.vcount=1;
      this.estatus=1;
    }
    if(this.vcount==0) {
      this.itemData = {
        "id":this.cid,
        "projectid":this.id,
        "client":this.client,
        "user":this.userid,
        "forclient":this.forclient,
        "name":this.name,
        "description":this.description,
        "start_date":this.start_date,
        "end_date":this.end_date,
        "status":this.status,
        "progress":this.progress,
        "cost":this.cost
      }
      await this.apiService.post('edit-project', this.itemData).then((res: any) => {
          this.btntxt="Saving Project..."
          this.router.navigate(['/projects/']);
      });
    }
  }

  view(id) {
    this.router.navigate(['/project-details/', { queryParams: { id: id }}]);
  }

}
