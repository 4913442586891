import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';

@Component({
  selector: 'app-pr-clients',
  templateUrl: './pr-clients.component.html',
  styleUrls: ['./pr-clients.component.scss']
})
export class PrClientsComponent implements OnInit {

  addTab: boolean;
  listTab: boolean = true;
  editTab: boolean;
  cid:any;

  name:any = '';
  companyname:any = '';
  mobile:any = '';
  email:any = '';

  ename:any = '';

  uname:any = '';
  ucompanyname:any = '';
  umobile:any = '';
  uemail:any = '';
  uimage:any = '';

  uename:any = '';

  btntxt:any = 'Add Client';
  savetxt:any = 'Save';
  profileimage:any;
  uprofileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  emessage:any;

  logon:any = '';

  clientlist:any;
  currentid:any = '';

  constructor(private apiService: ApiService) {

    this.cid = sessionStorage.getItem("cid");
    this.getClients();

  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab('3');
    this.currentid=id;
    this.getClient();
  }

  async remove(id) {
    if(confirm("Are you sure you want to remove this client?")) {
      this.itemData = {
        "clientid":id
      }
      await this.apiService.post('remove-client', this.itemData).then((res: any) => {
        this.getClients();
      });
    }
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.profileimage = file;
      }
  }

  uselectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.uprofileimage = file;
      }
  }

  onTab(number) {
    this.listTab = false;
    this.addTab = false;
    this.editTab = false;
    if (number == '1') {
      this.listTab = true;
    }
    else if (number == '2') {
      this.addTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  getClients() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('clients', this.itemData).then((res: any) => {
          this.clientlist=res.data;
      });
  }

  async getClient() {
      this.itemData = {
        "id":this.cid,
        "clientid":this.currentid
      }
      await this.apiService.post('single-client', this.itemData).then((res: any) => {
        this.uname=res.data[0].name;
        this.ucompanyname=res.data[0].company;
        this.uemail=res.data[0].email;
        this.umobile=res.data[0].phone;
        this.uimage=res.data[0].image;
      });
  }

  upload() {
    const formData = new FormData();
    if (this.profileimage !== undefined) {
      if(this.profileimage) {
        formData.append('logofile', this.profileimage);
      } else {
        formData.append('logofile', '');
      }
      formData.append('id', this.cid);
      formData.append('company', this.companyname);
      formData.append('name', this.name);
      formData.append('phone', this.mobile);
      formData.append('email', this.email);
      this.apiService.post('client', formData).then((res: any) => {
        this.btntxt="Saving Client..."
          this.btndisabled=1;
          if(res.status==200) {
            this.companyname='';
            this.name='';
            this.mobile='';
            this.email='';
            this.btndisabled=0;
            this.getClients();
            this.btntxt="Add Client";
            this.onTab(1);
          }
      });
    }
  }

  update() {
    const formData = new FormData();

      if(this.uprofileimage) {
        formData.append('logofile', this.uprofileimage);
      } else {
        formData.append('logofile', '');
      }
      formData.append('id', this.cid);
      formData.append('clientid', this.currentid);
      formData.append('company', this.ucompanyname);
      formData.append('name', this.uname);
      formData.append('phone', this.umobile);
      formData.append('email', this.uemail);
      this.apiService.post('client-update', formData).then((res: any) => {

          if(res.status==200) {
            this.ucompanyname='';
            this.uname='';
            this.umobile='';
            this.uemail='';
            this.uimage='';
            this.getClients();
            this.onTab(1);
          }
      });

  }

}
