import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  @ViewChild("myEditor", { static: false }) myEditor: any;

  Company: boolean = true;
  Localization: boolean;
  Permissions: boolean;
  Email: boolean;
  Invoice: boolean;
  Notifications: boolean;
  Changepassword: boolean;
  profileimage:any;
  bannerimage:any;

  btntxt:any = 'Upload Logo';
  savetxt:any = 'Save';
  itemData:any;
  btndisabled:any = 0;
  message:any;
  mylogo:any = '';
  mybanner:any = '';
  banner:any = '';
  logon:any = '';
  bannern:any = '';
  cid:any;
  filepath:any = '';

  companyname:any;
  name:any;
  phone:any;
  address:any;
  website:any;
  mobile:any;

  timezone:any = '';
  currency:any = '';
  currencysymbol:any = '';

  cccountry:any;
  cclanding:any = '';
  ccmargin:any = '';
  ccurrency:any = '';

  verror:any = 0;
  vcmessage:any = '';
  vlmessage:any = '';
  vmmessage:any = '';
  vcumessage:any = '';

  conversionlist:any;

  qvat:any;
  qvalidity:any;
  qpaymentterms:any;
  qdeliveryterms:any;
  data:any;

  oldpassword:any = '';
  newpassword:any = '';
  confirmpassword:any = '';
  vopass:any = '';
  vnpass:any = '';
  vcpass:any = '';
  userid:any;
  cpmessage:any = '';
  myheader:any;
  headerimage:any;
  headern:any;
  myrole:any;

  cptxt:any = 'Change Password';

  fy:any = '';

  public Editor = ClassicEditor;

  constructor(private session: SessionService, private apiService: ApiService,private router: Router,private activatedRoute: ActivatedRoute) {
    var offset = new Date().getTimezoneOffset();
    this.timezone=offset/60;
    this.myrole = sessionStorage.getItem("role");
    if(this.myrole>1) {
      this.router.navigate(['/dashboard']);
    }
  }


  onTab(number) {
    this.Company = false;
    this.Localization = false;
    this.Permissions = false;
    this.Email = false;
    this.Invoice = false;
    this.Notifications = false;
    this.Changepassword = false;

    if (number == '1') {
      this.Company = true;
    }
    else if (number == '2') {
      this.Localization = true;
    }
    else if (number == '3') {
      this.Permissions = true;
    }
    else if (number == '4') {
      this.Email = true;
    }
    else if (number == '5') {
      this.Invoice = true;
    }
    else if (number == '6') {
      this.Notifications = true;
    }
    else if (number == '7') {
      this.Changepassword = true;
    }
  }

  ngOnInit(): void {
    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.filepath = sessionStorage.getItem("filepath");
    this.getLogo();
    this.getCompany();
    this.getSettings();
    this.getConversion();
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.profileimage = file;
      }
  }

  selectBanner(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.bannerimage = file;
      }
  }

  selectHeader(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.headerimage = file;
      }
  }

  getLogo() {
    this.apiService.get('getlogo/'+this.cid).then((res: any) => {
      if(res.logo!='' && res.logo!=undefined && res.logo!=null) {
        this.logon=res.logo.replace('public','');
        this.logon = this.logon.replace('logo','');
        this.mylogo=this.filepath + this.logon;
        this.session.myLogo(this.mylogo);
      }

      if(res.banner!='' && res.banner!=undefined && res.banner!=null) {
        this.bannern=res.banner.replace('public','');
        this.bannern = this.bannern.replace('logo','');
        this.mybanner=this.filepath + this.bannern;
        this.session.myLogo(this.mylogo);
      }

      if(res.header!='' && res.header!=undefined && res.header!=null) {
        this.headern=res.header.replace('public','');
        this.headern = this.headern.replace('logo','');
        this.myheader=this.filepath + this.headern;
      }
    });
  }

  upload() {
    const formData = new FormData();
    if (this.profileimage !== undefined) {
      formData.append('logofile', this.profileimage);
      formData.append('company', this.cid);
      this.apiService.post('upload-logo', formData).then((res: any) => {
        this.btntxt="Uploading Logo..."
          this.btndisabled=1;
          if(res.status==200) {
            this.btndisabled=0;
            this.getLogo();
            this.btntxt="Upload Logo";
          }
      });
    }
  }

  updatecompany() {
    this.itemData = {
      "id":this.cid,
      "name":this.name,
      "company":this.companyname,
      "address":this.address,
      "mobile":this.mobile,
      "website":this.website
    }
    this.savetxt="Saving Details..."
    this.btndisabled=1;
    this.apiService.post('updatecompany', this.itemData).then((res: any) => {
      this.name=res.data[0].name;
      this.companyname=res.data[0].company;
      this.address=res.data[0].address;
      this.mobile=res.data[0].mobile;
      this.website=res.data[0].website;
      this.savetxt="Save"
      this.btndisabled=0;
    });
  }

  getCompany() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getcompany', this.itemData).then((res: any) => {
      this.name=res.data[0].name;
      this.companyname=res.data[0].company;
      this.address=res.data[0].address;
      this.mobile=res.data[0].mobile;
      this.website=res.data[0].website;
    });
  }

  getSettings() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('settings', this.itemData).then((res: any) => {
      if(res.data[0].timezone!='') {
        this.timezone=res.data[0].timezone;
      }
      this.currency=res.data[0].currency;
      this.currencysymbol=res.data[0].currencysymbol;
      this.qvat=res.data[0].vat;
      this.qvalidity=res.data[0].validity;
      this.qpaymentterms=res.data[0].paymentterms;
      this.qdeliveryterms=res.data[0].deliveryterms;
      this.data=res.data[0].terms;
      this.fy = res.data[0].fy;
    });
  }

  updatesettings() {
    const form2Data = new FormData();
    if (this.bannerimage !== undefined) {
      form2Data.append('logofile', this.bannerimage);
    } else {
      form2Data.append('logofile', '');
    }
    if (this.headerimage !== undefined) {
      form2Data.append('headerfile', this.headerimage);
    } else {
      form2Data.append('headerfile', '');
    }
    form2Data.append('company', this.cid);
    form2Data.append('timezone', this.timezone);
    form2Data.append('currency', this.currency);
    form2Data.append('currencysymbol', this.currencysymbol);
    form2Data.append('vat', this.qvat);
    form2Data.append('paymentterms', this.qpaymentterms);
    form2Data.append('deliveryterms', this.qdeliveryterms);
    form2Data.append('terms', this.myEditor.data);
    form2Data.append('validity', this.qvalidity);
    form2Data.append('fy', this.fy);
    /*this.itemData = {
      "id":this.cid,
      "timezone":this.timezone,
      "currency":this.currency,
      "currencysymbol":this.currencysymbol,
      "vat":this.qvat,
      "validity":this.qvalidity,
      "paymentterms":this.qpaymentterms,
      "deliveryterms":this.qdeliveryterms,
      "terms":this.myEditor.data
    }*/
    this.savetxt="Saving Details..."
    this.btndisabled=1;
    this.apiService.post('updatesettings', form2Data).then((res: any) => {
      if(res.data[0].timezone!='') {
        this.timezone=res.data[0].timezone;
      }
      this.currency=res.data[0].currency;
      this.currencysymbol=res.data[0].currencysymbol;
      this.savetxt="Save"
      this.btndisabled=0;
      this.getLogo();
    });
  }

  addConversion() {
    this.verror = 0;
    this.vcmessage='';
    this.vlmessage='';
    this.vmmessage='';
    this.vcumessage='';
    if(this.cccountry=='' || this.cccountry==undefined) {
      this.vcmessage="Please select a country";
      this.verror=1;
    }
    if(this.cclanding=='' || this.cclanding==undefined || isNaN(this.cclanding)) {
      this.vlmessage="Please enter valid Landing cost";
      this.verror=1;
    }
    if(this.ccmargin=='' || this.ccmargin==undefined || isNaN(this.ccmargin)) {
      this.vmmessage="Please enter valid Profit Margin Percentage";
      this.verror=1;
    }
    if(this.ccurrency=='' || this.ccurrency==undefined) {
      this.vcumessage="Please enter Currency";
      this.verror=1;
    }
    if(this.verror==0) {
      this.itemData = {
        "id":this.cid,
        "ccountry":this.cccountry,
        "clanding":this.cclanding,
        "cmargin":this.ccmargin,
        "ccurrency":this.ccurrency
      }
      this.savetxt="Saving Details..."
      this.btndisabled=1;
      this.apiService.post('addConversion', this.itemData).then((res: any) => {
        this.conversionlist=res.data;
        this.savetxt="Save"
        this.btndisabled=0;
      });
    }
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.conversionlist=res.data;
    });
  }

  delete(id) {
    if(confirm("Sure you want to delete this record?")) {
      this.itemData = {
        "id":id
      }
      this.apiService.post('deleteConversion', this.itemData).then((res: any) => {
        this.getConversion();
      });
    }
  }

  changepass() {
    this.verror=0;
    this.vopass=0;
    this.vnpass=0;
    this.vcpass=0;
    if(this.oldpassword=='') {
      this.verror=1;
      this.vopass=1;
    }
    if(this.newpassword=='') {
      this.verror=1;
      this.vnpass=1;
    }
    if(this.confirmpassword=='') {
      this.verror=1;
      this.vcpass=1;
    }
    if(this.confirmpassword!=this.newpassword) {
      this.verror=1;
      this.vcpass=1;
    }
    if(this.verror==0) {
      this.itemData = {
        "id":this.cid,
        "userid":this.userid,
        "oldpassword":this.oldpassword,
        "newpassword":this.newpassword
      }
      this.cptxt="Changing Password..."
      this.btndisabled=1;
      this.apiService.post('change-password', this.itemData).then((res: any) => {
        this.cptxt="Change Password"
        this.btndisabled=0;
        this.cpmessage=res.msg;
        this.oldpassword='';
        this.newpassword='';
        this.confirmpassword='';
      });
    }
  }

}
