import { Component, OnInit, ViewChild, ElementRef  } from '@angular/core';
import { ApiService } from '../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import * as xlsx from 'xlsx';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  exportlist:any = '';
  title:any  = 'Attendance List';

  contactTab: boolean;
  chatTab: boolean = true;
  editTab: boolean;

  name:any='';
  uname:any='';
  address:any='';
  uaddress:any='';
  email:any='';
  uemail:any='';
  mobile:any='';
  umobile:any='';
  role:any='';
  urole:any='';
  password:any='';
  btntxt:any = 'Add Staff';
  ubtntxt:any = 'Update Staff Details'
  itemData:any='';
  btndisabled:any = 0;
  message:any='';
  catlist:any='';

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  erole:any = 0;
  epassword:any = 0;
  eemail:any = 0;
  euname:any = 0;

  uvcount:any = 0;
  uename:any = 0;
  uerole:any = 0;
  ueemail:any = 0;

  cid:any;
  conversionlist:any;
  staffdet:any;
  currentstaff:any = '';
  myuserid:any;
  myrole:any;
  search:any = '';

  accountno:any = '';
  accountname:any = '';
  bank:any = '';
  branch:any = '';
  ifsc:any = '';
  basicsalary:any = '';
  ctc:any = '';
  sdate:any='';;
  adata:any ='';
  month:any = '';
  currentname:any = '';
  currentid:any = '';

  constructor(private apiService: ApiService,private router: Router,private activatedRoute: ActivatedRoute) {
    this.cid = sessionStorage.getItem("cid");
    this.myuserid = sessionStorage.getItem("userid");
    this.myrole = sessionStorage.getItem("role");
    if(this.myrole>1) {
      this.router.navigate(['/dashboard']);
    }

    this.myrole = sessionStorage.getItem("role");
    this.getvendor();
    this.getConversion();
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.conversionlist=res.data;
    });
  }

  ngOnInit(): void {
  }

  searchstaff() {
    this.itemData = {
      "sdate":this.sdate,
      "search":this.search
    }
    this.apiService.post('staffs', this.itemData).then((res: any) => {
        this.catlist=res.data;
    });
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }else if (number == '3') {
      this.editTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
  }

  changemonth(month:any) {
    this.month=month;
    this.viewatt(this.currentid,this.currentname);
  }

  viewatt(id:any,name:any) {
    this.chatTab = false;
    this.contactTab = true;
    this.editTab = false;
    this.currentname=name;
    this.currentid=id;
    this.itemData = {
      "userid":this.currentid,
      "month":this.month
    }
    this.apiService.post('staff-monthly-attendance', this.itemData).then((res: any) => {
        this.adata=res.data;
    });
  }

  getvendor() {
      this.itemData = {
        "cid":this.cid,
        "sdate":this.sdate
      }
      this.apiService.post('staff-attendance', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  async mark(status:any,id:any) {
    this.currentstaff=id;
      this.itemData = {
        "cid":this.cid,
        "id":id,
        "status":status,
        "sdate":this.sdate,
      }
      await this.apiService.post('mark-attendance', this.itemData).then((res: any) => {
          this.getvendor();
      });
  }

  addvendor() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.email=='') {
        this.eemail=1;
        this.vcount=1;
      }
      if(this.password=='' || this.password==undefined) {
        this.epassword=1;
        this.vcount=1;
      }
      if(this.role=='' || this.role==undefined) {
        this.erole=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "address":this.address,
            "mobile":this.mobile,
            "email":this.email,
            "role":this.role,
            "password":this.password,
          }
          this.apiService.post('staff', this.itemData).then((res: any) => {
            this.btntxt="Adding Staff..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Staff';
                this.btndisabled=0;
                this.catlist=res.data;
                this.name='';
                this.address='';
                this.onTab(1);
              }
          });
      }
  }

  removeStaff(id) {
    if(confirm("Are you sure you want to delete this Staff?")) {
        this.itemData = {
          "id":this.cid,
          "userid":id
        }
        this.apiService.post('remove-staff', this.itemData).then((res: any) => {
              this.catlist=res.data;
        });
    }
  }

  async exportdata() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('staffs', this.itemData).then((res: any) => {
          this.exportlist=res.data;
      });
  }

  export() {
    const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.title + '.xlsx');
  }


}
