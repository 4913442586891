import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';

@Component({
  selector: 'app-fundallocate',
  templateUrl: './fundallocate.component.html',
  styleUrls: ['./fundallocate.component.scss']
})
export class FundallocateComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;
  reqTab:boolean;

  categories:any = '';
  institutions:any = '';


  name:any='';
  description:any='';
  rdescription:any = '';
  tag:any = '';
  serialno:any = '';
  cost:any = 0;
  rcost:any = 0;
  dop:any = '';
  qty:any = '0';
  institutionid:any = '';
  categoryid:any = '0';
  assettypeid:any = '';
  location:any = '';


  btntxt:any = 'Allocate Funds';

  uname:any='';
  udescription:any='';
  utag:any = '';
  userialno:any = '';
  ucost:any = 0;
  udop:any = '';
  uqty:any = '';
  uinstitutionid:any = '';
  ucategoryid:any = '';
  ulocation:any = '';

  ubtntxt:any = 'Edit Fund Allocations';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;
  ecost:any = 0;
  einstitution:any = 0;
  eassettype:any = 0;
  edop:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  list:any = '';
  funds:any = '';
  assettypes:any = '';
  currentbalance:any = 0;
  institute:any = '';
  allrequests:any = '';
  userid:any = '';
  constructor(private apiService: ApiService,private session: SessionService,) {
    this.myrole = sessionStorage.getItem("role");
    this.institute = sessionStorage.getItem("institute");
    this.userid = sessionStorage.getItem("userid");
    if(this.myrole!=1 && this.myrole!=2 && this.institute==1) {
      this.onTab(4);
    }
    this.get();
    this.getfund();
    this.getcategories();
    this.getinstitutions();
    this.getRequest();
  }

  ngOnInit(): void {
  }

  get_data(event) {
    const selectEl = event.target;
    this.currentbalance= selectEl.options[selectEl.selectedIndex].getAttribute('data-balance');
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.single(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    this.reqTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
    else if (number == '4') {
      this.reqTab = true;
    }
  }

  getcategories() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('categories', this.itemData).then((res: any) => {
          this.categories=res.data;
      });
  }

  getinstitutions() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('institutions', this.itemData).then((res: any) => {
          this.institutions=res.data;
      });
  }

  getfund() {
    this.itemData = {
      "identity":'fund'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.funds=res.data;
    });
  }

  searchvalues(searchval) {
    this.itemData = {
        search:searchval,
        "identity":'fund_allocation'
      }
      this.apiService.post('search-data', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  single(id) {
      this.itemData = {
        "id":id,
        "identity":'fund_allocation'
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.udescription=res.data[0].description;
      });
  }

  remove(id) {
    if(confirm("Are you sure you want to delete this Fund Allocation?")) {
        this.itemData = {
          "id":id,
          "identity":'fund_allocation'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.get();
        });
    }
  }

  get() {
      this.itemData = {
        "identity":'fund_allocation'
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  async approve(id,val) {
    this.itemData = {
      "id":id,
      "approval":val,
      "paid":0,
      "identity":'fund_request',
      "role":this.myrole,
      "userid":this.userid,
      "type":'approval'
    }
    await this.apiService.post('update-data', this.itemData).then((res: any) => {
        this.getRequest();
    });
  }

  async paid(id,val) {
    this.itemData = {
      "id":id,
      "paid":val,
      "identity":'fund_request',
      "role":this.myrole,
      "userid":this.userid,
      "type":'payment'
    }
    await this.apiService.post('update-data', this.itemData).then((res: any) => {
        this.getRequest();
    });
  }

  async add() {
      this.ename=0;
      this.einstitution=0;
      this.eassettype=0;
      this.ecost=0;
      this.vcount=0;
      if(this.institutionid=='') {
        this.einstitution=1;
        this.vcount=1;
      }
      if(this.dop=='') {
        this.edop=1;
        this.vcount=1;
      }
      if(this.cost=='' || this.cost==undefined || this.cost<1 || parseFloat(this.cost)>parseFloat(this.currentbalance)) {
        this.ecost=1;
        this.vcount=1;
      }
      if(this.assettypeid=='') {
        this.eassettype=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "description":this.description,
            "tag":this.tag,
            "serialno":this.serialno,
            "cost":this.cost,
            "dop":this.dop,
            "qty":this.qty,
            "institutionid":this.institutionid,
            "categoryid":this.categoryid,
            "location":this.location,
            "assettypeid":this.assettypeid,
            "identity":'fund_allocation'
          }
          await this.apiService.post('insert', this.itemData).then((res: any) => {
            this.btntxt="Allocating Funds..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Allocate Funds';
                this.btndisabled=0;
                this.list=res.data;
                this.name='';
                this.description='';
                this.session.myLoader(0);
                this.get();
                this.onTab(1);
              }
          });
      }
  }

  async update() {
    this.uename=0;
    this.vcount=0;
    if(this.uname=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "id":this.currentid,
          "name":this.uname,
          "description":this.udescription,
          "identity":'fund_allocation'
        }
        await this.apiService.post('update-data', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Fund Allocation..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Fund Allocation';
              this.btndisabled=0;
              this.list=res.data;
              this.uname='';
              this.udescription='';
              this.session.myLoader(0);
              this.get();
              this.onTab(1);
            }
        });
    }
  }

  async request() {
    this.ename=0;
    this.einstitution=0;
    this.eassettype=0;
    this.ecost=0;
    this.vcount=0;
    if(this.rcost=='' || this.rcost==undefined || this.rcost<1) {
      this.ecost=1;
      this.vcount=1;
    }
    if(this.vcount==0) {

        this.session.myLoader(1);
        this.itemData = {
          "userid":this.userid,
          "description":this.rdescription,
          "cost":this.rcost,
          "institutionid":this.institute,
          "identity":'fund_request'
        }
        await this.apiService.post('insert', this.itemData).then((res: any) => {
            if(res.status==200) {
              this.btndisabled=0;
              this.list=res.data;
              this.name='';
              this.description='';
              this.session.myLoader(0);
              this.getRequest();
              this.onTab(4);
            }
        });
    }
  }

  async getRequest() {
    this.itemData = {
      "identity":'fund_request'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.allrequests=res.data;
    });
  }

}
