import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { SessionService } from '../../../../session.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-feepayment',
  templateUrl: './feepayment.component.html',
  styleUrls: ['./feepayment.component.scss']
})
export class FeepaymentComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  studentid:any='';
  feeid:any = '';
  amount:any = '';
  fine:any='';
  remark:any = '';
  paymentdate:any = '';

  btntxt:any = 'Add Fee Payment';

  ubtntxt:any = 'Edit Fee Payment';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  list:any = '';
  institute:any = '';
  studentlist:any = '';
  feelist:any = '';
  constructor(private datePipe: DatePipe, private apiService: ApiService,private session: SessionService,) {
    this.myrole = sessionStorage.getItem("role");
    this.institute = sessionStorage.getItem("institute");
    this.get();
    this.getStudent();
    this.getFee();
    this.paymentdate = new Date();
    this.paymentdate = this.datePipe.transform(this.paymentdate,"yyyy-MM-dd");
  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.single(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchvalues(searchval) {
    this.itemData = {
        search:searchval,
        "identity":'student_fee'
      }
      this.apiService.post('search-data', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  single(id) {
      this.itemData = {
        "id":id,
        "identity":'student_fee'
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {

      });
  }

  remove(id) {
    if(confirm("Are you sure you want to delete this Fee Payment?")) {
        this.itemData = {
          "id":id,
          "identity":'student_fee'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.get();
        });
    }
  }

  get() {
      this.itemData = {
        "identity":'student_fee'
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  getStudent() {
    this.itemData = {
      "identity":'student'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.studentlist=res.data;
    });
  }

  getFee() {
    this.itemData = {
      "identity":'fee'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.feelist=res.data;
    });
  }

  async add() {
      this.ename=0;
      this.vcount=0;
      if(this.amount=='' || this.amount<0) {
        this.ename=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "studentid":this.studentid,
            "feeid":this.feeid,
            "amount":this.amount,
            "fine":this.fine,
            "paymentdate":this.paymentdate,
            "remark":this.remark,
            "institute":this.institute,
            "identity":'student_fee'
          }
          await this.apiService.post('insert', this.itemData).then((res: any) => {
            this.btntxt="Adding Fee Schedule..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Fee Schedule';
                this.btndisabled=0;
                this.list=res.data;
                this.session.myLoader(0);
                this.get();
                this.onTab(1);
              }
          });
      }
  }


}
