import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../api.service';

import { SessionService } from '../../../session.service';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-institution',
  templateUrl: './institution.component.html',
  styleUrls: ['./institution.component.scss']
})
export class InstitutionComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  exportlist:any = '';
  title:any  = 'Institution List';

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  zones:any = '';
  divisions:any = '';

  name:any='';
  description:any='';
  zone:any = '';
  division:any = '';
  address:any = '';
  location:any = '';
  city:any = '';
  pincode:any = '';
  phone:any = '';
  email:any = '';

  btntxt:any = 'Add Institution';

  uname:any='';
  udescription:any='';
  uzone:any = '';
  udivision:any = '';
  uaddress:any = '';
  ulocation:any = '';
  ucity:any = '';
  upincode:any = '';
  uphone:any = '';
  uemail:any = '';

  emessage:any = '';

  ubtntxt:any = 'Edit Institution';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  ezone:any = 0;
  edivision:any = 0;
  eaddress:any = '';
  uename:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  constructor(private apiService: ApiService,private session: SessionService,) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
    this.getcategories();
    this.getzones();
    this.getdivisions();
  }

  ngOnInit(): void {
  }

  getzones() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('zones', this.itemData).then((res: any) => {
          this.zones=res.data;
      });
  }

  getdivisions() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('divisions', this.itemData).then((res: any) => {
          this.divisions=res.data;
      });
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.getSingle(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchcategory(searchval) {
    this.itemData = {
        "id":this.cid,
        search:searchval
      }
      this.apiService.post('search-institution', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  getSingle(id) {
      this.itemData = {
        "id":id,
        "identity":"institution"
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.udescription=res.data[0].description;
          this.uzone=res.data[0].zoneid;
          this.udivision=res.data[0].divisionid;
          this.uaddress=res.data[0].address;
          this.ulocation=res.data[0].location;
          this.ucity=res.data[0].city;
          this.upincode=res.data[0].pincode;
          this.uphone=res.data[0].phone;
          this.uemail=res.data[0].email;
      });
  }

  removeProduct(id) {
    if(confirm("Are you sure you want to delete this Institution?")) {
        this.itemData = {
          "id":id,
          "identity":"institution"
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.getcategories();
        });
    }
  }

  getcategories() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('institutions', this.itemData).then((res: any) => {
          this.catlist=res.data;
      });
  }

  async addcategory() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.zone=='') {
        this.ezone=1;
        this.vcount=1;
      }
      if(this.division=='') {
        this.edivision=1;
        this.vcount=1;
      }
      if(this.address=='') {
        this.eaddress=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "description":this.description,
            "zone":this.zone,
            "division":this.division,
            "address":this.address,
            "location":this.location,
            "city":this.city,
            "pincode":this.pincode,
            "phone":this.phone,
            "email":this.email,
          }
          await this.apiService.post('institution', this.itemData).then((res: any) => {
            this.btntxt="Adding Institution..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Institution';
                this.btndisabled=0;
                this.catlist=res.data;
                this.name='';
                this.description='';
                this.zone='';
                this.division='';
                this.address='';
                this.location='';
                this.city='';
                this.pincode='';
                this.phone='';
                this.email='';
                this.session.myLoader(0);
                this.onTab(1);
              }
          });
      }
  }

  async editcategory() {
    this.uename=0;
    this.vcount=0;
    if(this.uname=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "id":this.currentid,
          "name":this.uname,
          "description":this.udescription,
          "zone":this.uzone,
          "division":this.udivision,
          "address":this.uaddress,
          "location":this.ulocation,
          "city":this.ucity,
          "pincode":this.upincode,
          "phone":this.uphone,
          "email":this.uemail,
          "identity":"institution"
        }
        await this.apiService.post('update-data', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Institution..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Institution';
              this.btndisabled=0;
              this.uname='';
              this.udescription='';
              this.uzone='';
              this.udivision='';
              this.uaddress='';
              this.ulocation='';
              this.ucity='';
              this.upincode='';
              this.uphone='';
              this.uemail='';
              this.getcategories();
              this.session.myLoader(0);
              this.onTab(1);
            }
        });
    }
  }

  async exportdata() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('institutions', this.itemData).then((res: any) => {
          this.exportlist=res.data;
      });
  }

  export() {
    const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.title + '.xlsx');
  }

}
