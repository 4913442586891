import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isFullScreen: boolean;
  contactTab: boolean;
  groupTab: boolean;
  chatTab: boolean = true;
  title: any
  userid:any;
  fy:any = '';
  notcount:any = 0;
  institute:any = '';
  constructor(private session: SessionService, private route: Router, private apiService: ApiService) {
    this.session.myUserid$.subscribe((data) => {
        this.userid = data;
        sessionStorage.setItem("userid", data);
        if(this.userid=='' || this.userid==undefined) {
          this.route.navigate(['/login']);
        }
    });

    this.fy = sessionStorage.getItem("fy");

    this.session.myFy$.subscribe((data) => {
        this.fy = data;
        sessionStorage.setItem("fy", data);
        if(this.fy=='' || this.fy==undefined) {
          this.fy=new Date().getFullYear();
        }
        console.log("hel"+this.fy)
    });

    if (sessionStorage.getItem("userid") != "" && sessionStorage.getItem("userid") != null) {
      this.userid = sessionStorage.getItem("userid");
      this.institute = sessionStorage.getItem("institute");
    } else {
      this.route.navigate(['/login']);
    }
    this.title = route.url;
    //debugger
    this.title = this.title.replace(/\//g, '');
    this.title = this.title.replace('-', ' ');

    this.title = this.title.split(';')[0];
    this.title = this.stripQueryStringAndHashFromPath(this.title);
    this.title = this.title.toUpperCase();
    this.getNotifications();
  }

  getNotifications() {
      const data = {

      }
      this.apiService.post('expiry-notifications', data).then((res: any) => {
          this.notcount=res.count;
      });
      setTimeout(() => {
        this.getNotifications();
    }, 200000);
  }

  stripQueryStringAndHashFromPath(url) {
    return url.split("?")[0].split("#")[0];
  }

  ngOnInit(): void {


  }
  mToggoleMenu() {
    document.getElementsByTagName('body')[0].classList.toggle("offcanvas-active");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");

  }
  noteToggle() {
    document.getElementsByClassName('sticky-note')[0].classList.toggle("open");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }
  openRightMenu() {
    document.getElementById('rightbar').classList.toggle("open");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");

  }
  openfullScreen() {

    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen ||
      elem.requestFullscreen || elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      methodToBeInvoked.call(elem)
    }
    this.isFullScreen = true;
  }

  closeFullScreen() {
    const docWithBrowsersExitFunctions = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    if (docWithBrowsersExitFunctions.exitFullscreen) {
      docWithBrowsersExitFunctions.exitFullscreen();
    } else if (docWithBrowsersExitFunctions.mozCancelFullScreen) { /* Firefox */
      docWithBrowsersExitFunctions.mozCancelFullScreen();
    } else if (docWithBrowsersExitFunctions.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      docWithBrowsersExitFunctions.webkitExitFullscreen();
    } else if (docWithBrowsersExitFunctions.msExitFullscreen) { /* IE/Edge */
      docWithBrowsersExitFunctions.msExitFullscreen();
    }
    this.isFullScreen = false;
  }

  onTab(number) {
    this.chatTab = false;
    this.groupTab = false;
    this.contactTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.groupTab = true;
    }
    else if (number == '3') {
      this.contactTab = true;
    }
  }

  logout() {
    sessionStorage.setItem("userid", '');
    sessionStorage.setItem("name", '');
    sessionStorage.setItem("role", '');
    sessionStorage.setItem("company", '');
    sessionStorage.setItem("cid", '');
    this.session.myUserid('');
    this.session.myName('');
    this.session.myRole('');
    this.session.myCompany('');
    this.session.myCid('');
  }

  changefy(val:any) {
    sessionStorage.setItem("fy", val);
    this.session.myFy(val);
  }

  toggleMenu() {
    const body = document.getElementsByTagName('body')[0];

    if (body.classList.contains('offcanvas-active')) {
      body.classList.remove('offcanvas-active');
    }
    else {
      body.classList.add('offcanvas-active');
    }
  }
  cToggoleMenu() {
    const body = document.getElementsByTagName('body')[0].classList.remove("offcanvas-active");
    document.getElementsByClassName('overlay')[0].classList.toggle("open");
  }

}
