import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ApiService } from '../../../../api.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-projectview',
  templateUrl: './projectview.component.html',
  styleUrls: ['./projectview.component.scss']
})
export class ProjectviewComponent implements OnInit {

  cid:any;

  @ViewChild("myEditor", { static: false }) myEditor: any;

  Summary: boolean = true;
  Modules: boolean;
  Milestone:boolean;
  Documents:boolean;
  Tasks: boolean;
  Testing: boolean;

  name:any = '';
  userid:any = '';
  client:any = '';
  forclient:any = '';
  description:any = '';
  start_date:any = '';
  end_date:any = '';
  status:any = '';
  progress:any = '';
  cost:any = '';

  btntxt:any = 'Add Project';
  savetxt:any = 'Save';
  profileimage:any;
  filepath:any = 'http://localhost:3000/api/images/';
  itemData:any;
  btndisabled:any = 0;
  message:any;

  logon:any = '';

  clientdata:any;
  statusdata:any;
  projectdata:any;
  pid:any;
  about:any;
  notes:any;
  notemessage:any;
  notetxt:any = 'Add Notes';
  data:any = '';

  public Editor = ClassicEditor;

  statuslist:any;
  currenttab:any = 1;
  showaddmodule:any = 0;
  showaddmilestone:any = 0;
  stafflist:any = '';

  modulename:any = '';
  modulestaff:any = '';
  moduledescription:any = '';
  modulestart_date:any = '';
  moduleend_date:any = '';
  modulestatus:any = 'planned';
  moduleprogress:any = '0';

  milestoneno:any = '';
  milestonename:any = '';
  milestonedescription:any = '';
  milestonedate:any = '';
  expense:any = 0;
  income:any = 0;
  milestonestatus:any = 'Pending';

  emilestoneno:any = 0;
  emilestonename:any = 0;
  emilestonedate:any = 0;
  mmessage:any = '';

  umilestoneno:any = '';
  umilestonename:any = '';
  umilestonedescription:any = '';
  umilestonedate:any = '';
  uexpense:any = 0;
  uincome:any = 0;
  umilestonestatus:any = 'Pending';

  uemilestoneno:any = 0;
  uemilestonename:any = 0;
  uemilestonedate:any = 0;
  ummessage:any = '';

  emodulename:any = 0;
  emodulestaff:any = 0;
  emodulestart_date:any = 0;
  emoduleend_date:any = 0;

  modulelist:any = '';
  module:any = '';
  tasklist:any = '';
  currentmoduleid:any = '';

  cmodulestatus:any = '';
  cmoduleprogress:any = '';
  msmsg:any = '';

  modulenote:any = '';
  mnotemessage:any = '';
  modulemessage:any = '';
  modnotes:any = '';
  milestones:any = '';
  ustatus:any = '';

  currentmilestoneid:any = '';

  vcount:any = 0;

  doctitle:any = '';
  docurl:any = '';
  docmessage:any = '';

  edocurl:any = 0;
  edoctitle:any = 0;
  documentlist:any = '';

  constructor(private apiService: ApiService, private router: Router,
    private activatedRoute: ActivatedRoute) {
    this.pid = this.activatedRoute.snapshot.queryParamMap.get('id');
    console.log(this.pid);
    this.cid = sessionStorage.getItem("cid");
    this.userid = sessionStorage.getItem("userid");
    this.getStatus();
    this.getProject();
    this.getstaff();
    this.getModules();
    this.getMilestones();
    this.getDocuments();
  }

  ngOnInit(): void {
  }

  getstaff() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('staffs', this.itemData).then((res: any) => {
          this.stafflist=res.data;
      });
  }

  addnote() {
    this.itemData = {
      "id":this.cid,
      "project":this.pid,
      "note":this.myEditor.data,
      "userid":this.userid
    }
    this.apiService.post('note', this.itemData).then((res: any) => {
        this.notes = res.data;
        this.data='';
    });
  }

  addmnote() {
    this.itemData = {
      "id":this.cid,
      "project":this.pid,
      "note":this.modulenote,
      "userid":this.userid,
      "module":this.currentmoduleid
    }
    this.apiService.post('modulenote', this.itemData).then((res: any) => {
        this.modnotes = res.data;
        this.modulenote='';
    });
  }

  deleteModuleNote(id) {
    if(confirm("Are you sure you want to delete this note?")) {
      this.itemData = {
        "id":id,
        "module":this.currentmoduleid
      }
      this.apiService.post('deletemodulenote', this.itemData).then((res: any) => {
          this.modnotes = res.data;
      });
    }
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.docurl = file;
      }
  }

  onTab(number) {
    this.currenttab = number;
    this.Summary = false;
    this.Modules = false;
    this.Milestone = false;
    this.Tasks = false;
    this.Testing = false;
    this.Documents = false;

    if (number == '1') {
      this.Summary = true;
    }
    else if (number == '2') {
      this.Modules = true;
    }
    else if (number == '3') {
      this.Tasks = true;
    }
    else if (number == '6') {
      this.Milestone = true;
    }
    else if (number == '7') {
      this.Documents = true;
    }
    else if (number == '4') {
      this.Testing = true;
    }
  }

  getProject() {
      this.itemData = {
        "id":this.cid,
        "project":this.pid
      }
      this.apiService.post('project-view', this.itemData).then((res: any) => {
          this.projectdata=res.data;
          this.about=res.data[0].description;
          this.clientdata = res.client;
          this.notes = res.notes;
      });
  }

  getStatus() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('allstatus', this.itemData).then((res: any) => {
          this.statuslist=res.data;
      });
  }

  getDocuments() {
    this.itemData = {
      "projectid":this.pid
    }
    this.apiService.post('project-documents', this.itemData).then((res: any) => {
        this.documentlist=res.data;
    });
  }

  upload() {
      this.itemData = {
        "id":this.cid,
        "client":this.client,
        "user":this.userid,
        "forclient":this.forclient,
        "name":this.name,
        "description":this.description,
        "start_date":this.start_date,
        "end_date":this.end_date,
        "status":this.status,
        "progress":this.progress,
        "cost":this.cost
      }
      this.apiService.post('project', this.itemData).then((res: any) => {
          this.btntxt="Saving Project..."
          this.btndisabled=1;
          if(res.status==200) {
            this.forclient='';
            this.name='';
            this.description='';
            this.start_date='';
            this.end_date='';
            this.progress='';
            this.status='';
            this.cost='';
            this.btndisabled=0;
            this.btntxt="Add Project";
            this.onTab(1);
          }
      });
  }

  uploaddoc() {
    const formData = new FormData();
    if (this.docurl !== undefined) {
      if(this.docurl) {
        formData.append('document', this.docurl);
      } else {
        formData.append('document', '');
      }
      formData.append('projectid', this.pid);
      formData.append('title', this.doctitle);
      this.apiService.post('project-document', formData).then((res: any) => {
          if(res.status==200) {
            this.doctitle='';
            this.docurl='';
            this.getDocuments();
            this.btntxt="Add Document";
            this.onTab(7);
          }
      });
    }
  }

  removedoc(id) {
    if(confirm("Are you sure you want to delete this Document?")) {
        this.itemData = {
          "id":id,
          "identity":'project_document'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.getDocuments();
        });
    }
  }

  showaddmodulefunc() {
    this.showaddmodule=1;
  }

  hideaddmodulefunc() {
    this.showaddmodule=0;
  }

  showaddmilestonefunc() {
    this.showaddmilestone=1;
  }

  hideaddmilestonefunc() {
    this.showaddmilestone=0;
  }

  async editmilestone(id) {
    this.currentmilestoneid=id;
    this.showaddmilestone=-1;
    this.itemData = {
      "milestoneid":id,
    }
    await this.apiService.post('get-project-milestone', this.itemData).then((res: any) => {
        this.umilestoneno=res.data[0].milestoneno;
        this.umilestonename=res.data[0].milestone;
        this.umilestonedescription=res.data[0].description;
        this.umilestonedate=res.data[0].milestonedate;
        this.uincome=res.data[0].income;
        this.uexpense=res.data[0].expense;
        this.ustatus=res.data[0].status;
    });
  }

  async removemilestone(id) {
    if(confirm("Are you sure you want to remove this milestone?")) {
      this.itemData = {
        "milestoneid":id,
      }
      await this.apiService.post('delete-project-milestone', this.itemData).then((res: any) => {
        this.getMilestones();
      });
    }
  }

  async showtask(id) {
    this.currentmoduleid=id;
    this.itemData = {
      "pid":this.pid,
      "id":this.userid,
      "module":id
    }
    await this.apiService.post('project-tasks', this.itemData).then((res: any) => {
        this.modnotes=res.notes;
        this.module=res.module
        this.cmodulestatus=res.module[0].status;
        this.cmoduleprogress=res.module[0].progress;
        this.onTab(3);
    });
  }

  async updatemodulestatus() {
    this.itemData = {
      "pid":this.pid,
      "id":this.userid,
      "module":this.currentmoduleid,
      "status":this.cmodulestatus,
      "progress":this.cmoduleprogress
    }
    await this.apiService.post('update-project-module', this.itemData).then((res: any) => {
        this.showtask(this.currentmoduleid);
        this.msmsg='Module Status Updated';
        this.getModules();
        setTimeout(() => {
            this.msmsg='';
        }, 10000);
    });
  }

  getModules() {
      this.itemData = {
        "identity":'project_module',
        "pid":this.pid
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.modulelist=res.data;
      });
  }

  getMilestones() {
    this.itemData = {
      "projectid":this.pid
    }
    this.apiService.post('project-milestones', this.itemData).then((res: any) => {
        this.milestones=res.data;
    });
  }

  addModule() {
    this.itemData = {
      "id":this.pid,
      "owner":this.modulestaff,
      "userid":this.userid,
      "name":this.modulename,
      "description":this.moduledescription,
      "start_date":this.modulestart_date,
      "end_date":this.moduleend_date,
      "status":this.modulestatus,
      "progress":this.moduleprogress,
      "identity":'project_module'
    }
    this.apiService.post('insert', this.itemData).then((res: any) => {
        if(res.status==200) {
          this.modulename='';
          this.moduledescription='';
          this.modulestart_date='';
          this.moduleend_date='';
          this.moduleprogress='';
          this.modulestatus='planned';
          this.modulestaff='';
          this.btndisabled=0;
          this.onTab(2);
          this.getModules();
          this.hideaddmodulefunc();
        }
    });
  }

  addMilestone() {
    this.emilestoneno=0;
    this.emilestonedate=0;
    this.emilestonename=0;
    this.vcount=0;
    if(this.milestoneno=='' || this.milestoneno==undefined || this.milestoneno==0) {
      this.emilestoneno=1;
      this.vcount=1;
    }
    if(this.milestonename=='' || this.milestonename==undefined) {
      this.emilestonename=1;
      this.vcount=1;
    }
    if(this.milestonedate=='' || this.milestonedate==undefined) {
      this.emilestonedate=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
      this.itemData = {
        "projectid":this.pid,
        "milestoneno":this.milestoneno,
        "milestonename":this.milestonename,
        "description":this.milestonedescription,
        "date":this.milestonedate,
        "expense":this.expense,
        "income":this.income,
        "milestonestatus":this.milestonestatus,
      }
      this.apiService.post('project-new-milestone', this.itemData).then((res: any) => {
          if(res.status==200) {
            this.milestoneno='';
            this.milestonename='';
            this.milestonedescription='';
            this.milestonedate='';
            this.expense='';
            this.income='';
            this.milestonestatus='';
            this.onTab(6);
            this.getMilestones();
            this.hideaddmilestonefunc();
          }
      });
    }
  }

  updateMilestone() {
    this.uemilestoneno=0;
    this.uemilestonedate=0;
    this.uemilestonename=0;
    this.vcount=0;
    if(this.umilestoneno=='' || this.umilestoneno==undefined || this.umilestoneno==0) {
      this.uemilestoneno=1;
      this.vcount=1;
    }
    if(this.umilestonename=='' || this.umilestonename==undefined) {
      this.uemilestonename=1;
      this.vcount=1;
    }
    if(this.umilestonedate=='' || this.umilestonedate==undefined) {
      this.uemilestonedate=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
      this.itemData = {
        "milestoneid":this.currentmilestoneid,
        "milestoneno":this.umilestoneno,
        "milestonename":this.umilestonename,
        "description":this.umilestonedescription,
        "date":this.umilestonedate,
        "expense":this.uexpense,
        "income":this.uincome,
        "milestonestatus":this.umilestonestatus,
      }
      this.apiService.post('project-edit-milestone', this.itemData).then((res: any) => {
          if(res.status==200) {
            this.umilestoneno='';
            this.umilestonename='';
            this.umilestonedescription='';
            this.umilestonedate='';
            this.uexpense='';
            this.uincome='';
            this.umilestonestatus='';
            this.onTab(6);
            this.getMilestones();
            this.hideaddmilestonefunc();
          }
      });
    }
  }
}
