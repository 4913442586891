import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from '../../../api.service';
import { SessionService } from '../../../session.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss']
})
export class MembersComponent implements OnInit {

  @ViewChild('epltable', { static: false }) epltable: ElementRef;

  contactTab: boolean;
  chatTab: boolean = true;
  editTab: boolean;

  name:any='';
  joindate:any = '';
  ujoindate:any = '';
  uname:any='';
  address:any='';
  uaddress:any='';
  email:any='';
  uemail:any='';
  mobile:any='';
  umobile:any='';
  role:any='';
  urole:any='';
  password:any='';
  btntxt:any = 'Add Member';
  ubtntxt:any = 'Update Member Details'
  itemData:any='';
  btndisabled:any = 0;
  message:any='';
  catlist:any='';

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  erole:any = 0;
  epassword:any = 0;
  eemail:any = 0;
  euname:any = 0;

  uvcount:any = 0;
  uename:any = 0;
  uerole:any = 0;
  ueemail:any = 0;

  cid:any;
  conversionlist:any;
  staffdet:any;
  currentstaff:any = '';
  myuserid:any;
  myrole:any;
  search:any = '';

  accountno:any = '';
  accountname:any = '';
  bank:any = '';
  branch:any = '';
  ifsc:any = '';
  basicsalary:any = '';
  ctc:any = '';
  mujoindate:any = '';

  zones:any = '';
  zoneid:any = '0';
  nominee:any = '';
  memcategory:any = 'A';
  refno:any = '';
  memstatus:any = 'active';
  memno:any = '';

  uzoneid:any = '0';
  unominee:any = '';
  umemcategory:any = 'A';
  urefno:any = '';
  umemstatus:any = 'active';
  umemno:any = '';

  ezone:any = 0;
  loader:any = 0;

  title:any  = 'Member List';

  pageno:any = 1;
  total_pages:any = 1;
  numbers:any ;
  exportlist:any = '';
  newpageno:any = 1;

  constructor(private session: SessionService,private apiService: ApiService,private router: Router,private activatedRoute: ActivatedRoute) {
    this.cid = sessionStorage.getItem("cid");
    this.myuserid = sessionStorage.getItem("userid");
    this.myrole = sessionStorage.getItem("role");
    if(this.myrole>1) {
      this.router.navigate(['/dashboard']);
    }

    this.myrole = sessionStorage.getItem("role");
    this.getvendor();
    this.getConversion();
    this.getZone();
    this.exportdata();
  }

  getConversion() {
    this.itemData = {
      "id":this.cid
    }
    this.apiService.post('getConversion', this.itemData).then((res: any) => {
      this.conversionlist=res.data;
    });
  }

  ngOnInit(): void {
  }

  async searchstaff(val:any) {
    //this.loader=1;
    this.itemData = {
      "id":this.cid,
      "search":val,
      "pageno":this.pageno
    }
    await this.apiService.post('members', this.itemData).then((res: any) => {
        this.catlist=res.data;
        this.total_pages=Math.ceil(res.total);
        this.numbers = [];
          for (let index = 1; index <= this.total_pages; index++) {
            this.numbers.push(index);
          } 
    });
    //this.loader=0;
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }else if (number == '3') {
      this.editTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
  }

  async getvendor() {
      this.loader=1;
      this.itemData = {
        "id":this.cid,
        "pageno":this.pageno
      }
      await this.apiService.post('members', this.itemData).then((res: any) => {
          this.catlist=res.data;
          this.total_pages=Math.ceil(res.total);
          this.numbers = [];
          for (let index = 1; index <= this.total_pages; index++) {
            this.numbers.push(index);
          } 
      });
      this.loader=0;
  }

  getZone() {
      this.itemData = {
        "id":this.cid
      }
      this.apiService.post('zones', this.itemData).then((res: any) => {
          this.zones=res.data;
      });
  }

  getStaffDetail(id) {
    this.currentstaff=id;
    this.onTab(3);
      this.itemData = {
        "id":this.cid,
        "userid":id

      }
      this.apiService.post('member-detail', this.itemData).then((res: any) => {
          this.staffdet=res.data;
          this.uname=res.data[0].name;
          this.uaddress=res.data[0].address;
          this.umobile=res.data[0].phone;
          this.uemail=res.data[0].email;
          this.umemno=res.data[0].memno;
          this.uzoneid=res.data[0].zoneid;
          this.umemstatus=res.data[0].memstatus;
          this.umemcategory=res.data[0].memcategory;
          this.unominee=res.data[0].nominee;
          this.mujoindate=res.data[0].joined;
      });
  }

  async addvendor() {
      this.ename=0;
      this.ezone=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.email=='') {
        this.eemail=1;
        this.vcount=1;
      }
      if(this.zoneid=='' || this.zoneid==0) {
        this.ezone=1;
        this.vcount=1;
      }
      if(this.vcount==0) {
          this.loader=1;
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "address":this.address,
            "mobile":this.mobile,
            "email":this.email,
            "joindate":this.joindate,
            "memno":this.memno,
            "memcategory":this.memcategory,
            "memstatus":this.memstatus,
            "nominee":this.nominee,
            "zoneid":this.zoneid,
          }
          await this.apiService.post('member', this.itemData).then((res: any) => {
            this.btntxt="Adding Member..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Member';
                this.btndisabled=0;
                this.catlist=res.data;
                this.name='';
                this.address='';
                this.mobile='';
                this.email='';
                this.joindate='';
                this.onTab(1);
                this.getvendor();
              }
          });
          this.loader=0;
      }
  }

  async removeStaff(id) {
    if(confirm("Are you sure you want to delete this Member?")) {
        this.loader=1;
        this.itemData = {
          "id":this.cid,
          "userid":id
        }
        await this.apiService.post('remove-member', this.itemData).then((res: any) => {
              this.catlist=res.data;
        });
        this.loader=0;
    }
  }

  async updateStaff() {
      this.euname=0;
      this.uvcount=0;
      if(this.uname=='') {
        this.uename=1;
        this.uvcount=1;
      }
      if(this.uvcount==0) {
          this.loader=1;
          this.itemData = {
            "id":this.cid,
            "name":this.uname,
            "address":this.uaddress,
            "phone":this.umobile,
            "userid":this.currentstaff,
            "joindate":this.ujoindate,
            "memno":this.umemno,
            "memcategory":this.umemcategory,
            "memstatus":this.umemstatus,
            "nominee":this.unominee,
            "zoneid":this.uzoneid,
          }
          await this.apiService.post('member-edit', this.itemData).then((res: any) => {
            this.ubtntxt="Updating Member Details..."
              this.btndisabled=1;
              if(res.status==200) {
                this.ubtntxt='Update Member Details';
                this.btndisabled=0;
                this.uname='';
                this.uaddress='';
                this.umobile='';
                this.uemail='';
                this.urole='';
                this.currentstaff='';
                this.catlist=res.data;
                this.onTab(1);
              }
          });
          this.loader=0;
      }
  }

  prev() {
    this.pageno=this.pageno - 1;
    this.newpageno = this.pageno;
    this.searchstaff(this.search);
  }

  next() {
    this.pageno=this.pageno + 1;
    this.newpageno = this.pageno;
    this.searchstaff(this.search);
  }

  last() {
    this.pageno=this.total_pages;
    this.newpageno = this.pageno;
    this.searchstaff(this.search);
  }

  first() {
    this.pageno=1;
    this.newpageno = this.pageno;
    this.searchstaff(this.search);
  }

  getPage() {
    if(isNaN(this.newpageno)) {
      this.newpageno=this.pageno;
    }
    if(this.newpageno>this.total_pages) {
      this.newpageno=this.total_pages;
    }
    if(this.newpageno<1) {
      this.newpageno=1;
    }
    if(this.pageno<=this.total_pages && this.pageno>0) {
      this.pageno=this.newpageno;
      this.searchstaff(this.search);
    }
  }

  async exportdata() {
    this.itemData = {
        "id":this.cid,
        "pageno":-1
      }
      await this.apiService.post('members', this.itemData).then((res: any) => {
          this.exportlist=res.data;
      });
  }

  export() {
    const ws: xlsx.WorkSheet =  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, this.title + '.xlsx');
  }

}
