import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { SessionService } from '../../../../session.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  name:any='';
  course:any = '';
  regno:any = '';
  address:any='';
  phone:any = '';
  email:any = '';
  doj:any = '';
  discount:any = 0;

  btntxt:any = 'Add Student';

  uname:any='';
  ucourse:any = '';
  uaddress:any='';
  uphone:any = '';
  uemail:any = '';
  udoj:any = '';
  uregno:any = '';
  udiscount:any = 0;
  emessage:any ='';

  ubtntxt:any = 'Edit Student';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  list:any = '';
  institute:any = '';
  courselist:any = '';
  constructor(private apiService: ApiService,private session: SessionService,) {
    this.myrole = sessionStorage.getItem("role");
    this.institute = sessionStorage.getItem("institute");
    this.get();
    this.getCourse();
  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.single(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchvalues(searchval) {
    this.itemData = {
        search:searchval,
        "identity":'student'
      }
      this.apiService.post('search-data', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  single(id) {
      this.itemData = {
        "id":id,
        "identity":'student'
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.uaddress=res.data[0].address;
          this.uregno=res.data[0].regno;
          this.ucourse=res.data[0].courseid;
          this.uphone=res.data[0].phone;
          this.uemail=res.data[0].email;
          this.udoj=res.data[0].doj;
          this.udiscount=res.data[0].discount;
      });
  }


  remove(id) {
    if(confirm("Are you sure you want to delete this Student?")) {
        this.itemData = {
          "id":id,
          "identity":'student'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.get();
        });
    }
  }

  get() {
      this.itemData = {
        "identity":'student'
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  getCourse() {
    this.itemData = {
      "identity":'course'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.courselist=res.data;
    });
  }

  async add() {
      this.ename=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "course":this.course,
            "regno":this.regno,
            "address":this.address,
            "phone":this.phone,
            "email":this.email,
            "doj":this.doj,
            "institute":this.institute,
            "discount":this.discount,
            "identity":'student'
          }
          await this.apiService.post('insert', this.itemData).then((res: any) => {
            this.btntxt="Adding Student..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Student';
                this.btndisabled=0;
                this.list=res.data;
                this.name='';
                this.session.myLoader(0);
                this.get();
                this.onTab(1);
              }
          });
      }
  }

  async update() {
    this.uename=0;
    this.vcount=0;
    if(this.uname=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "id":this.currentid,
          "name":this.uname,
          "course":this.ucourse,
          "regno":this.uregno,
          "address":this.uaddress,
          "phone":this.uphone,
          "email":this.uemail,
          "doj":this.udoj,
          "institute":this.institute,
          "discount":this.udiscount,
          "identity":'student'
        }
        await this.apiService.post('update-data', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Student..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Student';
              this.btndisabled=0;
              this.list=res.data;
              this.uname='';
              this.session.myLoader(0);
              this.get();
              this.onTab(1);
            }
        });
    }
  }

}
