import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  name:any='';

  btntxt:any = 'Upload Tally Data';

  uname:any='';

  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;

  cid:any;
  myrole:any;
  file:any = '';
  transactions:any = '';
  constructor(private apiService: ApiService) {
    this.cid = sessionStorage.getItem("cid");
    this.myrole = sessionStorage.getItem("role");
  }

  ngOnInit(): void {
    this.getImported();
  }


  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  selectImage(event) {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        this.file = file;
      }
  }

  async uploadExcel() {
    this.btntxt="Importing Data. This might take few minutes..."
    const formData = new FormData();
    if(this.file) {
      formData.append('tally', this.file);
      await this.apiService.post('import-tally-data', formData).then((res: any) => {

        this.btntxt="Upload Tally Data";
        this.transactions = res.tally;
        this.onTab(2);

      });
    }
  }

  async getImported() {
    const data = {

    }
    await this.apiService.post('imported-tally-data', data).then((res: any) => {

      this.transactions = res.tally;
      console.log(this.transactions);

    });
  }

}
