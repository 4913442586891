import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../api.service';
import { SessionService } from '../../../../session.service';

@Component({
  selector: 'app-fee',
  templateUrl: './fee.component.html',
  styleUrls: ['./fee.component.scss']
})
export class FeeComponent implements OnInit {

  contactTab: boolean;
  chatTab: boolean = true;
  editTab:boolean;

  name:any='';
  feeamount:any = '';
  fineamount:any = '';
  duedate:any='';
  fromdate:any = '';
  todate:any = '';
  remark:any = '';

  btntxt:any = 'Add FEE Schedule';

  uname:any='';
  ufeeamount:any = '';
  ufineamount:any = '';
  uduedate:any='';
  ufromdate:any = '';
  utodate:any = '';
  uremark:any = '';

  ubtntxt:any = 'Edit FEE Schedule';


  itemData:any;
  btndisabled:any = 0;
  message:any;
  catlist:any;

  //validation variables
  vcount:any = 0;
  ename:any = 0;
  uename:any = 0;
  eduedate:any = 0;
  ueduedate:any = 0;
  efromdate:any = 0;
  uefromdate:any = 0;
  etodate:any = 0;
  uetodate:any = 0;
  efeeamount:any = 0;
  uefeeamount:any = 0;


  cid:any;
  currentid:any;
  search:any;
  myrole:any;
  list:any = '';
  institute:any = '';
  courselist:any = '';
  constructor(private apiService: ApiService,private session: SessionService,) {
    this.myrole = sessionStorage.getItem("role");
    this.institute = sessionStorage.getItem("institute");
    this.get();
    this.getCourse();
  }

  ngOnInit(): void {
  }

  edit(id) {
    this.onTab(3);
    this.currentid=id;
    this.single(id);
  }

  onTab(number) {
    this.chatTab = false;
    this.contactTab = false;
    this.editTab = false;
    if (number == '1') {
      this.chatTab = true;
    }
    else if (number == '2') {
      this.contactTab = true;
    }
    else if (number == '3') {
      this.editTab = true;
    }
  }

  searchvalues(searchval) {
    this.itemData = {
        search:searchval,
        "identity":'fee'
      }
      this.apiService.post('search-data', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  single(id) {
      this.itemData = {
        "id":id,
        "identity":'fee'
      }
      this.apiService.post('get-data', this.itemData).then((res: any) => {
          this.uname=res.data[0].name;
          this.ufeeamount=res.data[0].amount;
          this.uduedate=res.data[0].duedate;
          this.ufromdate=res.data[0].fromdate;
          this.utodate=res.data[0].todate;
          this.ufineamount=res.data[0].fineperday;
          this.uremark=res.data[0].remark;
      });
  }

  remove(id) {
    if(confirm("Are you sure you want to delete this Fee?")) {
        this.itemData = {
          "id":id,
          "identity":'fee'
        }
        this.apiService.post('remove-data', this.itemData).then((res: any) => {
              this.get();
        });
    }
  }

  get() {
      this.itemData = {
        "identity":'fee'
      }
      this.apiService.post('datas', this.itemData).then((res: any) => {
          this.list=res.data;
      });
  }

  getCourse() {
    this.itemData = {
      "identity":'fee'
    }
    this.apiService.post('datas', this.itemData).then((res: any) => {
        this.courselist=res.data;
    });
  }

  async add() {
      this.ename=0;
      this.eduedate=0;
      this.efromdate=0;
      this.etodate=0;
      this.vcount=0;
      if(this.name=='') {
        this.ename=1;
        this.vcount=1;
      }
      if(this.duedate=='') {
        this.eduedate=1;
        this.vcount=1;
      }
      if(this.fromdate=='') {
        this.efromdate=1;
        this.vcount=1;
      }
      if(this.todate=='') {
        this.etodate=1;
        this.vcount=1;
      }
      if(this.vcount==0) {

          this.session.myLoader(1);
          this.itemData = {
            "id":this.cid,
            "name":this.name,
            "feeamount":this.feeamount,
            "fineamount":this.fineamount,
            "duedate":this.duedate,
            "fromdate":this.fromdate,
            "todate":this.todate,
            "remark":this.remark,
            "institute":this.institute,
            "identity":'fee'
          }
          await this.apiService.post('insert', this.itemData).then((res: any) => {
            this.btntxt="Adding Fee Schedule..."
              this.btndisabled=1;
              if(res.status==200) {
                this.btntxt='Add Fee Schedule';
                this.btndisabled=0;
                this.list=res.data;
                this.session.myLoader(0);
                this.feeamount=0;
                this.fineamount=0;
                this.name='';
                this.duedate='';
                this.fromdate='';
                this.todate='';
                this.remark='';
                this.get();
                this.onTab(1);
              }
          });
      }
  }

  async update() {
    this.uename=0;
    this.ueduedate=0;
    this.uefromdate=0;
    this.uetodate=0;
    this.vcount=0;
    if(this.uname=='') {
      this.uename=1;
      this.vcount=1;
    }
    if(this.uduedate=='') {
      this.ueduedate=1;
      this.vcount=1;
    }
    if(this.ufromdate=='') {
      this.uefromdate=1;
      this.vcount=1;
    }
    if(this.utodate=='') {
      this.etodate=1;
      this.vcount=1;
    }
    if(this.vcount==0) {
        this.session.myLoader(1);
        this.itemData = {
          "id":this.currentid,
          "name":this.uname,
          "feeamount":this.ufeeamount,
          "fineamount":this.ufineamount,
          "duedate":this.uduedate,
          "fromdate":this.ufromdate,
          "todate":this.utodate,
          "remark":this.uremark,
          "institute":this.institute,
          "identity":'fee'
        }
        await this.apiService.post('update-data', this.itemData).then((res: any) => {
          this.ubtntxt="Editing Fee Schedule..."
            this.btndisabled=1;
            if(res.status==200) {
              this.ubtntxt='Edit Fee Schedule';
              this.btndisabled=0;
              this.ufeeamount=0;
              this.ufineamount=0;
              this.uname='';
              this.uduedate='';
              this.ufromdate='';
              this.utodate='';
              this.uremark='';
              this.session.myLoader(0);
              this.get();
              this.onTab(1);
            }
        });
    }
  }

}
