import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly API_URL;
  responsedata: any;

  constructor(public http: HttpClient) {

    this.API_URL = 'https://sstrust.perutions.com/api/';
    //this.API_URL = 'http://localhost:3002/api/';
  }

  get(url: string) {
    return new Promise((resolve) => {
      this.http.get(this.API_URL + url).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  post(url: string, payload: object) {
    return new Promise((resolve) => {
      this.http.post(this.API_URL + url, payload).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          console.log(err);
          resolve({status: '204', msg:'Something went wrong. We will be back soon.'});
        }
      );
    });
  }

  patch(url: string, payload: object) {
    return new Promise((resolve) => {
      this.http.patch(this.API_URL + url, payload).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          console.log('error' + err);
        }
      );
    });
  }

  delete(url: string) {
    return new Promise((resolve) => {
      this.http.delete(this.API_URL + url).subscribe(
        (data: any) => {
          resolve(data);
        },
        (err) => {
          console.log('error' + err);
        }
      );
    });
  }
}
