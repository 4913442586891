import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { HRMSComponent } from './hrms/hrms.component';
import { HrUsersComponent } from './HRMS/hr-users/hr-users.component';
import { HrDashboardComponent } from './HRMS/hr-dashboard/hr-dashboard.component';
import { LeftmenuComponent } from './leftmenu/leftmenu.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSliderModule } from '@angular/material/slider';
import { NgApexchartsModule } from "ng-apexcharts";
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction'; // a plugin
import timeGridPlugin from '@fullcalendar/timegrid';
import { AgmCoreModule } from '@agm/core';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CountToModule } from 'angular-count-to';
import { FooterComponent } from './footer/footer.component';
import { ProductsComponent } from './hrms/products/products.component';
import { SettingsComponent } from './settings/settings.component';
import { CategoryComponent } from './hrms/category/category.component';
import { QuotationComponent } from './hrms/quotation/quotation.component';
import { VendorComponent } from './hrms/vendor/vendor.component';
import { CustomerComponent } from './hrms/customer/customer.component';
import { PurchaseComponent } from './hrms/purchase/purchase.component';
import { PrClientsComponent } from './project/pr-clients/pr-clients.component';
import { ProjectsComponent } from './project/projects/projects.component';
import { ProjectviewComponent } from './project/projectview/projectview.component';
import { ViewComponent } from './project/view/view.component';
import { PurchaseviewComponent } from './hrms/purchaseview/purchaseview.component';
import { QuotationviewComponent } from './hrms/quotationview/quotationview.component';
import { PermissionComponent } from './permission/permission.component';

import {NgxPrintModule} from 'ngx-print';
import {DatePipe} from '@angular/common';
import { StaffComponent } from './staff/staff.component';
import { LedgergroupComponent } from './finance/ledgergroup/ledgergroup.component';
import { LedgerComponent } from './finance/ledger/ledger.component';
import { EntryComponent } from './finance/entry/entry.component';
import { QuotationeditComponent } from './hrms/quotationedit/quotationedit.component';
import { SalesComponent } from './hrms/sales/sales.component';
import { SalesviewComponent } from './hrms/salesview/salesview.component';
import { ProjecteditComponent } from './project/projectedit/projectedit.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { DaybookComponent } from './finance/daybook/daybook.component';
import { StatementComponent } from './finance/statement/statement.component';
import { TrialbalanceComponent } from './finance/trialbalance/trialbalance.component';
import { ProfitlossComponent } from './finance/profitloss/profitloss.component';
import { BalancesheetComponent } from './finance/balancesheet/balancesheet.component';
import { MembersComponent } from './members/members.component';
import { MemberpaymentsComponent } from './memberpayments/memberpayments.component';
import { ZoneComponent } from './zone/zone.component';
import { DivisionComponent } from './division/division.component';
import { InstitutionComponent } from './institution/institution.component';
import { AssetComponent } from './asset/asset.component';
import { AssettypeComponent } from './assettype/assettype.component';
import { FundComponent } from './fund/fund.component';
import { FundallocateComponent } from './fundallocate/fundallocate.component';
import { LicenseComponent } from './license/license.component';
import { LicencenotificationsComponent } from './licencenotifications/licencenotifications.component';
import { SalarysummaryComponent } from './salarysummary/salarysummary.component';
import { AllowanceComponent } from './allowance/allowance.component';
import { DeductionComponent } from './deduction/deduction.component';
import { UserallowanceComponent } from './userallowance/userallowance.component';
import { UserdeductionComponent } from './userdeduction/userdeduction.component';
import { CourseComponent } from './education/course/course.component';
import { StudentComponent } from './education/student/student.component';
import { FeeComponent } from './education/fee/fee.component';
import { FeepaymentComponent } from './education/feepayment/feepayment.component';
import { FeesummaryComponent } from './education/feesummary/feesummary.component';
import { MeetingComponent } from './meeting/meeting.component';
import { ImportComponent } from './finance/import/import.component';
import { GrievanceComponent } from './grievance/grievance.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  imports: [
    CommonModule,
    ClientRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BsDatepickerModule.forRoot(),
    CollapseModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    AlertModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    CarouselModule.forRoot(),
    DragDropModule,
    MatSliderModule,
    NgApexchartsModule,
    NgMultiSelectDropDownModule.forRoot(),
    CountToModule,
    NgxPrintModule,
    ToastrModule.forRoot({
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'GOOGLE_API_KEY'
    }),
    FullCalendarModule,
    CKEditorModule
  ],
  declarations: [
    ClientRoutingModule.components,
    HeaderComponent,
    LeftmenuComponent,   
    HRMSComponent,
    HrUsersComponent,
    HrDashboardComponent,
    FooterComponent,
    ProductsComponent,
    SettingsComponent,
    CategoryComponent,
    QuotationComponent,
    VendorComponent,
    CustomerComponent,
    PurchaseComponent,
    PrClientsComponent,
    ProjectsComponent,
    ProjectviewComponent,
    ViewComponent,
    PurchaseviewComponent,
    QuotationviewComponent,
    PermissionComponent,
    StaffComponent,
    LedgergroupComponent,
    LedgerComponent,
    EntryComponent,
    QuotationeditComponent,
    SalesComponent,
    SalesviewComponent,
    ProjecteditComponent,
    AttendanceComponent,
    DaybookComponent,
    StatementComponent,
    TrialbalanceComponent,
    ProfitlossComponent,
    BalancesheetComponent,
    MembersComponent,
    MemberpaymentsComponent,
    ZoneComponent,
    DivisionComponent,
    InstitutionComponent,
    AssetComponent,
    AssettypeComponent,
    FundComponent,
    FundallocateComponent,
    LicenseComponent,
    LicencenotificationsComponent,
    SalarysummaryComponent,
    AllowanceComponent,
    DeductionComponent,
    UserallowanceComponent,
    UserdeductionComponent,
    CourseComponent,
    StudentComponent,
    FeeComponent,
    FeepaymentComponent,
    FeesummaryComponent,
    MeetingComponent,
    ImportComponent,
    GrievanceComponent
  ],
  providers: [BsDatepickerModule, DatePipe]
})
export class ClientModule { }