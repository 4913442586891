import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HrDashboardComponent } from './HRMS/hr-dashboard/hr-dashboard.component';
import { HrUsersComponent } from './HRMS/hr-users/hr-users.component';
import { ProductsComponent } from './hrms/products/products.component';
import { SettingsComponent } from './settings/settings.component';
import { CategoryComponent } from './hrms/category/category.component';
import { CustomerComponent } from './hrms/customer/customer.component';
import { PurchaseComponent } from './hrms/purchase/purchase.component';
import { PrClientsComponent } from './project/pr-clients/pr-clients.component';
import { ProjectsComponent } from './project/projects/projects.component';
import { ProjecteditComponent } from './project/projectedit/projectedit.component';
import { ProjectviewComponent } from './project/projectview/projectview.component';
import { QuotationviewComponent } from './hrms/quotationview/quotationview.component';
import { QuotationeditComponent } from './hrms/quotationedit/quotationedit.component';
import { PermissionComponent } from './permission/permission.component';
import { LedgergroupComponent } from './finance/ledgergroup/ledgergroup.component';
import { LedgerComponent } from './finance/ledger/ledger.component';
import { SalesComponent } from './hrms/sales/sales.component';
import { StaffComponent } from './staff/staff.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { EntryComponent } from './finance/entry/entry.component';
import { DaybookComponent } from './finance/daybook/daybook.component';
import { StatementComponent } from './finance/statement/statement.component';
import { TrialbalanceComponent } from './finance/trialbalance/trialbalance.component';
import { ProfitlossComponent } from './finance/profitloss/profitloss.component';
import { ImportComponent } from './finance/import/import.component';
import { BalancesheetComponent } from './finance/balancesheet/balancesheet.component';
import { MembersComponent } from './members/members.component';
import { MemberpaymentsComponent } from './memberpayments/memberpayments.component';
import { ZoneComponent } from './zone/zone.component';
import { QuotationComponent } from './quotation/quotation.component';
import { VendorComponent } from './hrms/vendor/vendor.component';
import { DivisionComponent } from './division/division.component';
import { InstitutionComponent } from './institution/institution.component';
import { AssettypeComponent } from './assettype/assettype.component';
import { AssetComponent } from './asset/asset.component';
import { FundComponent } from './fund/fund.component';
import { FundallocateComponent } from './fundallocate/fundallocate.component';
import { LicenseComponent } from './license/license.component';
import { LicencenotificationsComponent } from './licencenotifications/licencenotifications.component';
import { SalarysummaryComponent } from './salarysummary/salarysummary.component';
import { AllowanceComponent } from './allowance/allowance.component';
import { DeductionComponent } from './deduction/deduction.component';
import { UserallowanceComponent } from './userallowance/userallowance.component';
import { UserdeductionComponent } from './userdeduction/userdeduction.component';
import { CourseComponent } from './education/course/course.component';
import { StudentComponent } from './education/student/student.component';
import { FeeComponent } from './education/fee/fee.component';
import { FeepaymentComponent } from './education/feepayment/feepayment.component';
import { FeesummaryComponent } from './education/feesummary/feesummary.component';
import { MeetingComponent } from './meeting/meeting.component';
import { GrievanceComponent } from './grievance/grievance.component';


const routes: Routes = [
  {
    path: 'meeting',
    component: MeetingComponent,
    data: { title: ':: SS Trust :: Manage Meetings' }
  },
  {
    path: 'grievance',
    component: GrievanceComponent,
    data: { title: ':: SS Trust :: Grievance' }
  },
  {
    path: 'course',
    component: CourseComponent,
    data: { title: ':: SS Trust :: Manage Course' }
  },
  {
    path: 'students',
    component: StudentComponent,
    data: { title: ':: SS Trust :: Manage Students' }
  },
  {
    path: 'fee',
    component: FeeComponent,
    data: { title: ':: SS Trust :: Manage Fee Collection' }
  },
  {
    path: 'collection',
    component: FeepaymentComponent,
    data: { title: ':: SS Trust :: Manage Fees' }
  },
  {
    path: 'dashboard',
    component: HrDashboardComponent,
    data: { title: ':: SS Trust :: Home' }
  },
  {
    path: 'members',
    component: MembersComponent,
    data: { title: ':: SS Trust :: Members' }
  },
  {
    path: 'member-payments',
    component: MemberpaymentsComponent,
    data: { title: ':: SS Trust :: Member Payments' }
  },
  {
    path: 'departments',
    component: CategoryComponent,
    data: { title: ':: SS Trust :: Departments' }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    data: { title: ':: SS Trust :: Settings' }
  },
  {
    path: 'quotation-view',
    component: QuotationviewComponent,
    data: { title: ':: SS Trust :: Quotation Details' }
  },
  {
    path: 'quotation-edit',
    component: QuotationeditComponent,
    data: { title: ':: SS Trust :: Edit Quotation' }
  },
  {
    path: 'permission',
    component: PermissionComponent,
    data: { title: ':: SS Trust :: SS Trust Permissions' }
  },
  {
    path: 'staff',
    component: StaffComponent,
    data: { title: ':: SS Trust :: SS Trust Staff' }
  },
  {
    path: 'attendance',
    component: AttendanceComponent,
    data: { title: ':: SS Trust :: SS Trust Staff Attendance' }
  },
  {
    path: 'ledger-group',
    component: LedgergroupComponent,
    data: { title: ':: SS Trust :: Ledger Groups' }
  },
  {
    path: 'ledger',
    component: LedgerComponent,
    data: { title: ':: SS Trust :: Ledgers' }
  },
  {
    path: 'entry',
    component: EntryComponent,
    data: { title: ':: SS Trust :: Finance Entries' }
  },
  {
    path: 'daybook',
    component: DaybookComponent,
    data: { title: ':: SS Trust :: Finance Daybook' }
  },
  {
    path: 'statement',
    component: StatementComponent,
    data: { title: ':: SS Trust :: Finance Statement' }
  },
  {
    path: 'trial-balance',
    component: TrialbalanceComponent,
    data: { title: ':: SS Trust :: Finance Trial Balance' }
  },
  {
    path: 'profit-loss',
    component: ProfitlossComponent,
    data: { title: ':: SS Trust :: Finance Profit & Loss' }
  },
  {
    path: 'balance-sheet',
    component: BalancesheetComponent,
    data: { title: ':: SS Trust :: Finance Balance Sheet' }
  },
  {
    path: 'import-tally-data',
    component: ImportComponent,
    data: { title: ':: SS Trust :: Import Tally Data' }
  },
  {
    path: 'zone',
    component: ZoneComponent,
    data: { title: ':: SS Trust :: Zone Management' }
  },
  {
    path: 'division',
    component: DivisionComponent,
    data: { title: ':: SS Trust :: Division Management' }
  },
  {
    path: 'institution',
    component: InstitutionComponent,
    data: { title: ':: SS Trust :: Institution Management' }
  },
  {
    path: 'quotation',
    component: QuotationComponent,
    data: { title: ':: SS Trust :: Quotation' }
  },
  {
    path: 'vendor',
    component: VendorComponent,
    data: { title: ':: SS Trust :: Vendor' }
  },
  {
    path: 'product',
    component: ProductsComponent,
    data: { title: ':: SS Trust :: Products' }
  },
  {
    path: 'quotations',
    component: PurchaseComponent,
    data: { title: ':: SS Trust :: Quotation' }
  },
  {
    path: 'division',
    component: DivisionComponent,
    data: { title: ':: SS Trust :: Divisions' }
  },
  {
    path: 'assets',
    component: AssetComponent,
    data: { title: ':: SS Trust :: Assets' }
  },
  {
    path: 'assettype',
    component: AssettypeComponent,
    data: { title: ':: SS Trust :: Asset Type' }
  },
  {
    path: 'funds',
    component: FundComponent,
    data: { title: ':: SS Trust :: Funds' }
  },
  {
    path: 'allocate-funds',
    component: FundallocateComponent,
    data: { title: ':: SS Trust :: Allocate Funds' }
  },
  {
    path: 'license',
    component: LicenseComponent,
    data: { title: ':: SS Trust :: License' }
  },
  {
    path: 'license-notifications',
    component: LicencenotificationsComponent,
    data: { title: ':: SS Trust :: License Notifications' }
  },
  {
    path: 'generate-salary',
    component: SalarysummaryComponent,
    data: { title: ':: SS Trust :: Generate Salary' }
  },
  {
    path: 'allowance',
    component: AllowanceComponent,
    data: { title: ':: SS Trust :: Allowances' }
  },
  {
    path: 'deduction',
    component: DeductionComponent,
    data: { title: ':: SS Trust :: Deductions' }
  },
  {
    path: 'staff-allowance/:id/:name',
    component: UserallowanceComponent,
    data: { title: ':: SS Trust :: Staff Allowances' }
  },
  {
    path: 'staff-deduction/:id/:name',
    component: UserdeductionComponent,
    data: { title: ':: SS Trust :: Staff Deductions' }
  },
  {
    path: 'clients',
    component: PrClientsComponent,
    data: { title: ':: Finmaap :: Project Clients' }
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    data: { title: ':: Finmaap :: Projects' }
  },
  {
    path: 'project-edit',
    component: ProjecteditComponent,
    data: { title: ':: Finmaap :: Edit Project' }
  },
  {
    path: 'project-details',
    component: ProjectviewComponent,
    data: { title: ':: Finmaap :: Project Details' }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClientRoutingModule {
  static components = [
  ];

}
