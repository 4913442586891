import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../session.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  loader:any = 0;
  constructor(private session: SessionService) {
    this.session.myLoader$.subscribe((data) => {
        this.loader = data;
        if(this.loader=='' || this.loader==undefined) {
          this.loader=0
        }
    });
  }

  ngOnInit(): void {
  }

}
